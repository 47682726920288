import { db } from "../../../firebase";

    let licee = [];

    db.collection("licee").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

            licee.push(
                { 
                    title: doc.data().title,
                    image: doc.data().image,
                    desc: doc.data().desc,
                    dif: doc.data().dif,
                    link: doc.data().link
                })
        })
    })

export default { licee }

