import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import handleImg from '../../../assets/svgs/licee.svg'
import "./Alege.css"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CautareFacultate from './CautareFacultate';

const profiluri = [
    {
        label: "Stiinte Matematice"
    },
    {
        label: "Stiinte Fizice & Inginierie",
    },
    {
        label: "Stiinte Chimice & Chimie Industriala",
    },
    {
        label: "Informatica & IT",
    },
    {
        label: "Stiinte Biolgice",
    },
    {
        label: "Geografie - Geologie",
    },
    {
        label: "Limba - Literatura",
    },
    {
        label: "Istorie - Filozofie",
    },
    {
        label: "Arte & Arhitectura",
    },
    {
        label: "Cinematografie & Media",
    },
    {
        label: "Muzica",
    },
    {
        label: "Economie",
    },

]

function Alege() {

    const [search, setSearch] = useState(<img src={handleImg} alt="" className='alege__rightImage' ></img>);

    const [profil, setProfil] = useState('');

    const handleSearch = e => {
        e.preventDefault();

        setSearch(<CautareFacultate profil={profil} />)
    }


    return (
        <div className='alege'>
            <div className='alege__left'>
                <div className='alege__form'>
                    <div className='alege__title'>
                    <h2>Unde ai vrea sa studiezi?</h2>
                    </div>
                    <Autocomplete
                        id="combo-box-demo"
                        options={profiluri}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} onChange={e => setProfil(e.target.value)} value={profil} label="Profil" />}
                    />
                    <div className='alege__buttons'>
                    <Button variant='contained' onClick={handleSearch}>Cauta Facultate</Button>
                    </div>
                </div>
                <Accordion className='alege__accordion'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>De ce nu pot cauta un liceu?</Typography>
        </AccordionSummary>
        <AccordionDetails   >
          <Typography>
            Din nefericire un liceu necesita o cautare mult mai ampla care
            are nevoie de mai multe variabile. Planuim sa adaugam Cautare Liceu
            dar o sa mai dureze un pic :)
          </Typography>
        </AccordionDetails>
      </Accordion>
            </div>
            <div className='alege__separator'></div>
            <div className='alege__right'>
                {search}
            </div>
        </div>
    )
}

export default Alege