import './Common.css'

import logo from "../../assets/logos/logo.png"
import React, { useState } from 'react'
import Facultati from './Dashboard/Facultati'
import { Box } from '@mui/system'
import { BottomNavigation, BottomNavigationAction, Menu, MenuItem } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SchoolIcon from '@mui/icons-material/School';
import { auth } from '../../firebase';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Licee from './Dashboard/Licee'
import Alege from './Dashboard/Alege'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

function Common() {

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    let [choosen, setChoosen] = useState(<Alege />);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [value, setValue] = React.useState(0);
    const signOut = e => {
        auth.signOut();
    }

    const chooserFacultati = () => {
        setChoosen(<Facultati />)
    }
    const chooserLicee = () => {
        setChoosen(<Licee />)
    }
    const chooserAlege = () => {
        setChoosen(<Alege />)
    }

    return (
        <div className="common">
            <div className='common__appbar' >
                <AppBar className='common__appbar' position="static"  >
                    <Container maxWidth="xl" >
                        <Toolbar disableGutters>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                            >
                                <img src={logo} alt="logo" className='common__logo'></img>
                                ProjectAlpha
                            </Typography>

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center" ><a href='/' style={{color: "#000000"}}>Acasa</a></Typography>
                                        </MenuItem>
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center" ><a href='/about' style={{color: "#000000"}}>Despre</a></Typography>
                                        </MenuItem>
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center" ><a href='/contact' style={{color: "#000000"}}>Contact</a></Typography>
                                        </MenuItem>
                                </Menu>
                            </Box>
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                            >
                                <img src={logo} alt="logo" className='common__logo'></img>
                                ProjectAlpha
                            </Typography>
                            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex', p: 0 } }}>
                                    <Button
                                        href='/'
                                        onClick={handleCloseNavMenu}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        Acasa
                                    </Button>
                                    <Button
                                        href='/about'
                                        onClick={handleCloseNavMenu}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        Despre
                                    </Button>
                                    <Button
                                        href='/contact'
                                        onClick={handleCloseNavMenu}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        Contact
                                    </Button>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </div>
            {choosen}
            <div className="navbar" >
                <div className='navbar__mobile'>
                    <Box className='navbar__mobileBox' >
                        <BottomNavigation
                            showLabels
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                        >
                            <BottomNavigationAction label="Alege" onClick={chooserAlege} icon={<AllInclusiveIcon />} />
                            <BottomNavigationAction label="Licee" onClick={chooserLicee} icon={<HistoryEduIcon />} />
                            <BottomNavigationAction label="Facultati" onClick={chooserFacultati} icon={<SchoolIcon />} />
                            <BottomNavigationAction label="Mai multe" icon={<ExpandLessIcon />}
                                aria-controls={open ? 'menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            />
                        </BottomNavigation>
                    </Box>
                    <Menu
                        aria-labelledby="menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={signOut}>Delogare</MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    )
}

export default Common
