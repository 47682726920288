import React, { useState } from 'react'
import './Login.css'
import LoginArt from "../../assets/svgs/login.svg"
import Button from '@mui/material/Button';
import { auth, db } from '../../firebase';
import { Alert } from '@mui/material';

function SignUp() {

    const [error, setError] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const signUp = e => {
        e.preventDefault();

        auth
            .createUserWithEmailAndPassword(email, password)
            .then(auth => {
                return db.collection('users').add(auth.user.id).set({
                    name: "Los Angeles",
                    state: "CA",
                    country: "USA"
                })
            })
            .catch(error => setError(error.message))
    }

    return (
        <div className="login">
            <div className="login__space">
            {error ? <Alert severity="error">{error}</Alert> : "" }
                <div className="login__spaceForm" >
                    <h1 className="login__spaceFormTitle" >
                        Bun venit! Inregistrare:
                    </h1>
                    <h4 className="login__spaceFormSecondTitle" >
                        Va rugam sa va inregistrati inainte de a continua
                    </h4>
                    <input className="login__spaceFormUsername" type="text" name="Email" placeholder="Email..." value={email} onChange={e => setEmail(e.target.value)} />
                    <br />
                    <input className="login__spaceFormPassword" type="password" name="password" placeholder="Parola ta sigura..." value={password} onChange={e => setPassword(e.target.value)} />
                    <br />
                    <Button variant="contained" onClick={signUp} >Continuati</Button>
                </div>
            </div>
            <div className="login__art">
                <img src={LoginArt} alt="" className="login__artImg" />
            </div>
        </div>
    )
}

export default SignUp
