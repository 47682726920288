import React, { useState } from 'react'
import "./Landing.css"
import logo from "./resources/images/logo.png"

function Contact() {

    const [st, setSt] = useState("0px");

    const hphoneMenu = e => {
        setSt("0px")
    }

    const sphoneMenu =e => {
        setSt("200px")
    }
  return (
    <div>
        {/*------------header+prima pag-------------------*/}
        <section className="headerc">
          <nav>
            <a href="index.html"><img src={logo} /></a>
            <div className="nav_links" id="navlinks" style={{marginRight: st}} >
              <i className="fa fa-times" onClick={hphoneMenu} />
              <ul>
                <li><a href="/">Acasă</a> </li>
                <li><a href="/about">Despre</a></li>
                <li><a href="/contact">Contact</a></li>
                <li><a href="/dash">Login</a></li>
              </ul>
            </div>
            <i className="fa fa-bars" onClick={sphoneMenu} />
          </nav>
          <div className="text_box">
            <h1>Contact</h1>
            <p>Vrei să ne conctactezi?<br />
              Ne poți gasi pe următoarele platforme:</p>
            <div className="icons">
              <li><a href="https://github.com/IRules">GitHub: Toma Aris</a> </li>
              <li><a href="https://github.com/BLADR-ONE">GitHub: Ureche Rafael</a></li>
            </div>
            <div className="footer">
              <p>~Made with <i className="fa fa-heart" /> by AlphaTeam~</p>
            </div>
          </div>
        </section>
        {/*--------------Script meniu inchis/deschis----------------*/}
      </div>
  )
}

export default Contact