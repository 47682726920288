import './App.css';

import Common from './Panel/Common/Common';

import { auth } from './firebase';
import React, { useEffect } from 'react';
import { useStateValue } from './StateProvider';
import PanelAuth from './PanelAuth';

function Panel() {
    const [{ user }, dispatch] = useStateValue();
    useEffect(() => {

        auth.onAuthStateChanged((authUser) => {
            if (authUser) {

                dispatch({
                    type: "SET_USER",
                    user: authUser,
                });
            } else {
                dispatch({
                    type: "SET_USER",
                    user: null,
                });
            }
        });
    }, [dispatch]);
    return (
        <div className="App" style={{ background: '#263ec0' }}>
            {!user ? <PanelAuth/> : <Common />} 
        </div>
    );
}

export default Panel;