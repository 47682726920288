import React, { useEffect, useState } from 'react'
import "./CautareFacultate"
import { db } from "../../../firebase";
import PresentationCard from '../../../Components/PresentationCard';

function CautareFacultate(profil) {

    let facultati = [];

    db.collection("facultati").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            if (doc.data().domenii.indexOf(profil.profil) > -1) {
                facultati.push(
                    { 
                        title: doc.data().title,
                        image: doc.data().image,
                        desc: doc.data().desc,
                        dif: doc.data().dif,
                        link: doc.data().link
                    })
            }
        })
    })

    let [component, setComponent] = useState();
    useEffect(() => {
        setTimeout(() => setComponent(facultati.map(({title, image, dif, link, desc}, i) => (
            <PresentationCard title={title} image={image} dif={dif} link={link} desc={desc} />
         ))), 500)
    }, [])

  return (
    <div className="dashboard" >
                <div className='dashboard__cards'>
                {component}
                </div>
    </div>
  )
}

export default CautareFacultate