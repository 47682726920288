import './Dashboard.css'

import React, { useEffect, useState } from 'react'
import PresentationCard from '../../../Components/PresentationCard';
import data from './Licee.data.js'
function Licee() {

    let [compnent, setComponent] = useState();
    useEffect(() => {
        setTimeout(() => setComponent(data.licee.map(({title, image, dif, link, desc}, i) => (
            <PresentationCard title={title} image={image} dif={dif} link={link} desc={desc} />
         ))), 500)
    }, [])
    return (
        <>
            <div className="dashboard" >
                <div className='dashboard__cards'>
                {compnent}
                </div>
            </div>
        </>
    )
}

export default Licee;
