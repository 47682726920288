import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Rating, Typography } from '@mui/material'
import React from 'react'
import { Slide } from 'react-awesome-reveal'
import "./PresentationCard.css"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function PresentationCard({ title, image, dif, link, desc }) {
    return (
        <>
            <Slide className='card'>

                <Card className='card'>
                    <div className='card__cover'>
                        <img src={image} alt={title} className='card__coverImg' />
                    </div>
                    <div className='card__header'>
                        <div className='card__content'>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography>{title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="card__rating">
                                        <Rating name="read-only" value={dif} readOnly size='small' />
                                        </div>
                                        <p className='card__description'>
                                            {desc}
                                        </p>
                                        <Button variant="contained" href={link} >Website</Button>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>

                </Card>
            </Slide>
        </>
    )
}

export default PresentationCard