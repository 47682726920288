import React from 'react'
import SignUp from './Panel/Auth/Signup';
import Login from './Panel/Auth/Login'
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LoginIcon from '@mui/icons-material/Login';
import { Box } from '@mui/system';
import "./Panel.css"

function PanelAuth() {
  const [authMethod, setAuthMethod] = React.useState(0);
  return (
    <>
    {authMethod ? <SignUp /> : <Login />}
    <div className='auth__method'>
    <Box >
            <BottomNavigation
                showLabels
                value={authMethod}
                onChange={(event, newValue) => {
                setAuthMethod(newValue);
                }}
            >
              <BottomNavigationAction label="Logare" icon={<LockOpenIcon />} />
                <BottomNavigationAction label="Inregistrare" icon={<LoginIcon />} />
            </BottomNavigation>
    </Box> 
    </div>
    </>
  )
}

export default PanelAuth