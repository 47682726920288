import React, { useState } from 'react'
import "./Landing.css"
import logo from "./resources/images/logo.png"

function About() {
    const [st, setSt] = useState("0px");

    const hphoneMenu = e => {
        setSt("0px")
    }

    const sphoneMenu =e => {
        setSt("200px")
    }
  return (
    <div>
        {/*------------header+prima pag-------------------*/}
        <section className="headera">
          <nav>
            <a href="index.html"><img src={logo} /></a>
            <div className="nav_links" id="navlinks" style={{marginRight: st}} >
              <i className="fa fa-times" onClick={hphoneMenu} />
              <ul>
                <li><a href="/">Acasă</a> </li>
                <li><a href="/about">Despre</a></li>
                <li><a href="/contact">Contact</a></li>
                <li><a href="/dash">Login</a></li>
              </ul>
            </div>
            <i className="fa fa-bars" onClick={sphoneMenu} />
          </nav>
          <div className="text_box">
            <h1>Despre noi:</h1>
            <p>Suntem o ehipă de 2 elevi de la Colegiul Național Gheorghe Șincai,<br /> 
              Baia Mare, Maramureș și suntem pasionati de informatică,<br /> de aceea am 
              decis să facem acest site, pentru a demonstra cât de utilă <br />poate fi aceasta
              în contextul găsirii unui traseu in viață.</p>
            <div className="footer">
              <p>~Made with <i className="fa fa-heart" /> by AlphaTeam~</p>
            </div>
          </div>
        </section>
        {/*--------------Script meniu inchis/deschis----------------*/}
      </div>
  )
}

export default About