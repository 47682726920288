import './index.css';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { StateProvider } from './StateProvider';
import reducer, { initialState } from './reducer';

import App from './App';
import Error404 from './Error404';
import Panel from './Panel';
import React from 'react';
import ReactDOM from 'react-dom';
import Contact from './Landing/Contact';
import About from './Landing/About';

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <Router>
        <Routes>
          <Route exact path="/" element={<App />} />
          <Route exact path="/dash" element={<Panel />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </ StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
