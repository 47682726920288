import './404.css'

import { Button } from '@mui/material';
import React from 'react'
import image from './assets/svgs/404.svg'

function Error404() {
    return (
        <div className="error__404">
            <div className="error__404Text" >
                <h1 className="error__404TextTitle">404</h1>
                <h2 className="error__404TextSecondTitle">Pagina pierduta</h2>
                <p className="error__404TextText" >
                    Toata lumea pierde cateva pagini, poti sa pleci acasa sau sa cauti in continuare!
                </p>
                <br />
                <Button href='/' variant="contained">Acasa</Button>
            </div>
            <img src={image} className="error_404Img" alt="" />
        </div>
    )
}

export default Error404;
