import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyDOm3gRHcGQERP87pxPMhljrXTqyOhLJlc",
  authDomain: "projectalpha-9406f.firebaseapp.com",
  projectId: "projectalpha-9406f",
  storageBucket: "projectalpha-9406f.appspot.com",
  messagingSenderId: "839179382339",
  appId: "1:839179382339:web:3571d85782bb153518eef6",
  measurementId: "G-TH1NC90928"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth, db };