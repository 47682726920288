import { db } from "../../../firebase";

    let facultati = [];

    

    db.collection("facultati").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

            facultati.push(
                { 
                    title: doc.data().title,
                    image: doc.data().image,
                    desc: doc.data().desc,
                    dif: doc.data().dif,
                    link: doc.data().link
                })
        })
    })

export default { facultati }

